<template>
  <van-nav-bar
    :title="title"
  />
  <van-grid :column-num="4" style="margin:15px 0;">
    <van-grid-item>
      <p>
        <small>{{ v1 }} 元</small>
      </p>
      <p>
        <small>累计实收</small>
      </p>
    </van-grid-item>
    <van-grid-item>
      <p>
        <small>{{ v2 }} 元</small>
      </p>
      <p>
        <small>累计储值</small>
      </p>
    </van-grid-item>
    <van-grid-item>
      <p>
        <small>{{ v3 }} 元</small>
      </p>
      <p>
        <small>累计赠送</small>
      </p>
    </van-grid-item>
    <van-grid-item>
      <p>
        <small>{{ v4 }} 元</small>
      </p>
      <p>
        <small>待使用</small>
      </p>
    </van-grid-item>
  </van-grid>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
        <p style="margin:0 0 15px 0;">
          <small>充值金额 {{ item.depositAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>赠送金额 {{ item.depositGiftAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>实收金额 {{ item.payAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>分账金额 {{ item.profitSharingAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>最终收益 {{ item.profitAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;" v-if="item.member">
          <small>会员手机 {{ item.buyer.user.phone }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>时间 {{ item.createTime }}</small>
        </p>
        <van-button size="small" :to="{ name: 'Commission', query: { orderId: item.orderId } }">分润记录</van-button>
      </div>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      storeId: Cookies.get('storeId'),
      v1: 0,
      v2: 0,
      v3: 0,
      v4: 0
    })
    const getData = () => {
      post('/order.list', {
        pageNum: state.page,
        storeId: state.storeId,
        bizScope: 'DEPOSIT',
        status: 'COMPLETED'
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const sum = () => {
      post('/order.sumOrder', {
        storeId: state.storeId,
        bizScope: 'DEPOSIT',
        status: 'COMPLETED'
      }).then(res => {
        state.v1 = res.data.payAmount
        state.v2 = res.data.depositAmount
        state.v3 = res.data.depositGiftAmount
      })
      post('/member.sumDeposit', {
        storeId: state.storeId
      }).then(res => {
        state.v4 = res.data.total
      })
    }
    const init = () => {
      sum()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
